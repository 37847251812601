






































import { Vue, Component, Ref, Prop } from 'vue-property-decorator'
import SeletorDeEstado from '@/components/ui/SeletorDeEstado.vue'
import CamposDeImposto from './shareds/CamposDeImposto.vue'
import CampoDePercentual from '@/components/ui/CampoDePercentual.vue'
import CamposDeTipoDeOperacaoFiscal from './shareds/CamposDeTipoDeOperacaoFiscal.vue'
import { RegimeFiscal, RegraDeAplicacao, TipoDeClienteRegra } from '@/models'

@Component({
	components: {
		SeletorDeEstado,
		CamposDeImposto,
		CampoDePercentual,
		CamposDeTipoDeOperacaoFiscal,
	},
})

export default class DialogoDeRegraDeEstado extends Vue {
	@Ref() campoDeEstado!: SeletorDeEstado
	@Prop({ type: Boolean }) salvando!: boolean

	regraDeAplicacao: RegraDeAplicacao | null = null
	regimeFiscal: RegimeFiscal | null = null
	mostra = false
	tipoDeClienteRegra: TipoDeClienteRegra[] = []

	mostrar(regraDeAplicacao: RegraDeAplicacao, regimeFiscal: RegimeFiscal) {
		this.regraDeAplicacao = regraDeAplicacao

		this.regimeFiscal = regimeFiscal
		this.mostra = true
	}

	esconder() {
		this.mostra = false
	}
}
