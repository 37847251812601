









import { Vue, Component } from 'vue-property-decorator'
import { RegimeFiscal } from '@/models'
import { listaDeRegimesFiscais } from '@/shareds/fiscal'

@Component
export default class SeletorDeRegimeFiscal extends Vue {
	regimes: RegimeFiscal[] = listaDeRegimesFiscais
}
