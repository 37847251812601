









import { Vue, Component, Ref } from 'vue-property-decorator'
import ufs from '@/shareds/tabelas/ufs'

@Component
export default class SeletorDeEstado extends Vue {
	@Ref() wrapped!: Vue &
		HTMLInputElement &
		{ isMenuActive: boolean }
	ufs = ufs

	public focus() {
		this.wrapped.focus()
	}
}

