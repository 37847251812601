











import { Vue, Component } from 'vue-property-decorator'
import { FindCFOPUseCase } from '@/usecases'
import { CodigoEDescricao } from '@/models'

@Component
export default class SeletorDeCfop extends Vue {
	findCFOPUseCase = new FindCFOPUseCase()
	cfops: CodigoEDescricao[] = []

	async created() {
		this.cfops = (await this.findCFOPUseCase.find()).content
	}
}
