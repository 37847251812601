

































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { TipoDeOperacao } from '@/models/Recurso'
import type { TipoDeRecurso } from '@/models/Recurso'
import UserLoginStore from '@/store/vuex/authentication/UserLoginStore'
import { montarOperacaoDeRecurso } from '@/shareds/permissoes-shareds'

@Component
export default class DataTableDeCrud extends Vue  {
	@Prop({ type: String, default: 'Criar Novo' }) tituloParaCriar!: string
	@Prop({ type: Boolean, default: false }) hideActions!: boolean
	@Prop({ type: Boolean, default: false }) hideUpdate!: boolean
	@Prop({ type: Boolean, default: false }) hideDelete!: boolean
	@Prop({ type: Boolean, default: false }) hideCreate!: boolean
	@Prop({ type: String }) recurso?: TipoDeRecurso
	@Prop({ type: Boolean, default: true }) hideDetail!: boolean

	get recursoNormalizado() {
		return this.recurso || this.$route.meta?.recurso
	}

	get podeCriar() {
		if (this.hideCreate) return false
		return this.podeRealizarOperacao('criar')
	}

	get podeAlterar() {
		if (this.hideUpdate) return false
		return this.podeRealizarOperacao('alterar')
	}

	get podeDeletar() {
		if (this.hideDelete) return false
		return this.podeRealizarOperacao('deletar')
	}

	get headersNormalizado() {
		return this.hideActions
			? [...this.$attrs.headers]
			: [
				...this.$attrs.headers,
				{ text: 'Ações', value: 'actions', sortable: false, align: 'center' },
			]
	}

	podeRealizarOperacao(operacao: TipoDeOperacao) {
		return !this.recursoNormalizado || UserLoginStore.temAutorizacao(
			montarOperacaoDeRecurso(operacao, this.recursoNormalizado),
		)
	}
}

