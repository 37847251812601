

























































import { Component, Ref, Vue } from 'vue-property-decorator'
import SeletorDeRegimeFiscal from '@/components/fiscal/SeletorDeRegimeFiscal.vue'
import SeletorDeOrigem from '@/components/fiscal/SeletorDeOrigem.vue'
import CamposDeImposto from '@/views/application/configuracoes/fiscal/shareds/CamposDeImposto.vue'
import { obrigatorio } from '@/shareds/regras-de-form'
import { FindOrigemUseCase, SaveRegraDeImpostoUseCase } from '@/usecases'
import { CapaRegraImposto } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'

@Component({
	components: {
		SeletorDeRegimeFiscal,
		SeletorDeOrigem,
		CamposDeImposto,
	},
})
export default class DialogoDeCapaDeRegraFiscal extends Vue {
	@Ref() form!: HTMLFormElement
	@Ref() descricaoDaRegra!: HTMLInputElement

	obrigatorio = obrigatorio

	regra: CapaRegraImposto | null = null
	saveRegraDeImpostoUseCase = new SaveRegraDeImpostoUseCase()
	findOrigemUsecase = new FindOrigemUseCase()
	carregando = true
	salvando = false
	mostra = false

	mostrar(regra: CapaRegraImposto) {
		this.mostra = true
		this.regra = {...regra}

		setTimeout(() => {
			this.form.resetValidation()
			this.descricaoDaRegra.focus()
		})
	}

	esconder() {
		this.mostra = false
	}

	async salvar() {
		if (!this.regra) return
		try {
			this.salvando = true
			if (!this.regra.origem.id) throw new Error('Origem não possui id')
			this.regra = !this.regra.id
				? await this.saveRegraDeImpostoUseCase.create({
					...this.regra,
					origem: this.regra.origem.id,
				})
				: await this.saveRegraDeImpostoUseCase.updateCapa({
					...this.regra,
					origem: this.regra.origem.id,
				})
			AlertModule.setSuccess('Regra de Imposto salva com sucesso')
			this.$emit('salvar', this.regra)
		} catch (error: any) {
			AlertModule.setError(error)
		} finally {
			this.salvando = false
		}
	}

}
