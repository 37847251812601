














import { Component } from 'vue-property-decorator'
import CampoDecimalBase from './CampoDecimalBase.vue'

@Component
export default class CampoDecimal extends CampoDecimalBase  {
}

