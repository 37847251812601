











import { Vue, Component } from 'vue-property-decorator'
import { FindOrigemUseCase } from '@/usecases'
import { Origem } from '@/models'

@Component
export default class SeletorDeOrigem extends Vue {
	findOrigemuseCase = new FindOrigemUseCase()
	origens: Origem[] = []

	async created() {
		const origens = await this.findOrigemuseCase.find()
		this.origens = origens.content
	}
}
