












import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import { deformatarValorDecimal } from '@/shareds/formatadores'

@Component
export default class CampoDecimalBase extends Vue {
	$el!: HTMLInputElement

	@Ref() readonly campo!:
		HTMLInputElement &
		{ validate: () => boolean } &
		any

	@Prop() value!: number | null
	@Prop({ type: Number, default: 2 }) minimumFractionDigits!: number
	@Prop({ type: Number, default: 2 }) maximumFractionDigits!: number

	estaEditando = false
	ultimoPrecoDigitado: string | null = null

	created() {
		this.ultimoPrecoDigitado = typeof this.value === 'number'
			? this.value.toLocaleString('pt-BR', {
				minimumFractionDigits: this.minimumFractionDigits,
				maximumFractionDigits: this.maximumFractionDigits,
			})
			: null
	}

	get listeners() {
		const { input, change, ...listeners } = this.$listeners
		return listeners
	}

	set valorFormatado(preco: string | null) {
		this.ultimoPrecoDigitado = preco
		this.estaEditando = true
		if (preco === '-' || preco === '-,') {
			this.$emit('input', -0)
			return
		}
		if (preco === null || preco === '') {
			this.$emit('input', preco)
			return
		}

		const novoValor = deformatarValorDecimal(preco)
		this.$emit('input', novoValor)
	}

	get valorFormatado() {
		if (this.ultimoPrecoDigitado === '-') return this.ultimoPrecoDigitado
		if (typeof this.value !== 'number') return this.value
		if (this.ultimoPrecoDigitado && this.ultimoPrecoDigitado.match(/,$/)) return this.ultimoPrecoDigitado.replace(/[^-.,\d]/g, '')
		
		const digitos = !this.estaEditando ? null : this.digitos

		return this.value.toLocaleString('pt-BR', {
			minimumFractionDigits: digitos === null ? this.minimumFractionDigits : digitos,
			maximumFractionDigits: digitos === null ? this.maximumFractionDigits : digitos,
		})
	}

	get digitos() {
		if (!this.ultimoPrecoDigitado) return undefined
		return (this.ultimoPrecoDigitado.split(',')[1] || '').length
	}

	focus() {
		return this.campo.focus()
	}

	validate() {
		return this.campo.validate()
	}

	trocarTecla(event: KeyboardEvent) {
		const elemento = event.currentTarget as HTMLInputElement
		if (event.code !== 'Backspace' && event.code !== 'Delete' && event.code !== 'ArrowLeft' && event.code !== 'ArrowRight' && this.digitos === 2 && elemento.selectionStart === elemento.selectionEnd) {
			event.preventDefault()
			event.stopPropagation()
			return
		}

		if (event.key === ',' && this.ultimoPrecoDigitado && this.ultimoPrecoDigitado.includes(',')) {
			event.preventDefault()
			event.stopPropagation()
			return
		}
		if (
			event.key === '.' &&
			elemento.selectionStart === elemento.selectionEnd // cursor não está no final
		) {
			event.preventDefault()
			if (
				this.ultimoPrecoDigitado &&
				this.ultimoPrecoDigitado.includes(',')
			) {
				event.stopPropagation()
				return
			}
			this.valorFormatado = (this.valorFormatado || 0) + ','
			return
		}
		if (event.key === '-') {
			// Permite seleção de varios dês do inicio pode substituir um caracter '-' no inicio
			if (
				elemento.selectionStart === 0 &&
				elemento.selectionStart !== elemento.selectionEnd
			) return
			// Permite digitação de '-' no inicio quando o valor não está negativo
			if (
				elemento.selectionStart === 0 &&
				!this.ultimoPrecoDigitado?.startsWith('-')
			) return
			event.preventDefault()
			event.stopPropagation()
		}
	}

	@Watch('value')
	onChangeValue(value: number | null | '') {
		if (!this.ultimoPrecoDigitado) this.$emit('input', value)
		if (this.ultimoPrecoDigitado !== null && value === deformatarValorDecimal(this.ultimoPrecoDigitado)) return
		if (this.ultimoPrecoDigitado === '-' && value === 0) return
		this.ultimoPrecoDigitado = null
	}
}

