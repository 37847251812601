











import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import CampoDecimal from '@/components/ui/CampoDecimal.vue'

@Component({
	components: {
		CampoDecimal,
	},
})
export default class CampoDePercentual extends Vue {
	@Ref() campo!: CampoDecimal
	@Prop({ type: Boolean, default: true }) usarPercentual!: boolean
	@Prop() value!: number | null | ''

	get listeners() {
		const { input, change, ...listeners } = this.$listeners
		return listeners
	}

	get valorPercentual() {
		return this.value === null || this.value === '' || this.usarPercentual
			? this.value
			: this.value * 100
	}

	set valorPercentual(valor: number | null | '') {
		this.$emit('input', valor === null || valor === '' || this.usarPercentual
			? valor
			: valor / 100,
		)
	}

	focus() {
		return this.campo.focus()
	}

	validate() {
		return this.campo.validate()
	}
}

