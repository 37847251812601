











import { CST, TipoDeImposto } from '@/models'
import { findCSTs, sortByCodigo } from '@/shareds/fiscal'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class SeletorDeCsosn extends Vue {
	csosns: CST[] = []

	async created() {
		this.csosns = await findCSTs('CSOSN' as TipoDeImposto)
	}

	get CSOSNsOrdenados() {
		return this.csosns.sort(sortByCodigo)
	}
}
