










import { Vue, Component, Ref } from 'vue-property-decorator'

@Component
export default class SeletorDeImposto extends Vue {
	@Ref() campo!: HTMLInputElement

	$refs!: {
		campo: HTMLInputElement
	}

	impostos = [
		'ICMS',
		'PIS',
		'COFINS',
	]

	focus() {
		this.campo.focus()
	}
}

