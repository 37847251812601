import { Pageable } from '@/models/Pageable'
import { DataOptions } from 'vuetify'

/**
 * Converte paginação estilo Vuetify para Pageable spring boot
 * @param paginacao paginacao estilo Vuetify
 * @example
 * { sortBy: ['campo1', 'campo2'], sortDesc: [true, false] }
 * [ 'campo1,DESC', 'campo2,ASC' ]
 */
export const sortConverter = (paginacao: DataOptions): string[] =>
	paginacao.sortBy.map(
		(sort, indice) => `${sort},${paginacao.sortDesc[indice] ? 'DESC' : 'ASC'}`,
	)

type VuetifySort = Pick<DataOptions, 'sortBy' | 'sortDesc'>

/**
 * Converte paginação estilo Spring Boot para Vuetify
 * @param paginacao paginacao estilo Vuetify
 * @example
 * [ 'campo1,DESC', 'campo2,ASC' ]
 * { sortBy: ['campo1', 'campo2'], sortDesc: [true, false] }
 */
export const springToVuetifyPageSort = (paginacao?: Pageable): VuetifySort => {
	const sort = paginacao?.sort;
	if (!sort) return {
		sortBy: [],
		sortDesc: [],
	}
	return (typeof sort === 'string' ? sort.split(',') : [])
		.reduce((pageSort, springSort): VuetifySort => {
			const [ campo, ordem ] = springSort.split(',')
			return {
				sortBy: [
					...pageSort.sortBy,
					campo,
				],
				sortDesc: [
					...pageSort.sortDesc,
					ordem === 'DESC',
				],
			}
		}, {
			sortBy: [],
			sortDesc: [],
		} as VuetifySort)
}